@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.Card {
    width: auto;
    min-width: 40%;
    height: auto;
    max-height: 87%;
    background-color: #FFFFFF;
    padding: 2.5%;
    border-radius: 5px;
    scrollbar-width: none;
    overflow: hidden;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.divider {
    width: 100%;
    height: 1px;
    background-color: #F1F2F6;
    margin-bottom: 15px;
}
.Sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
    height: 92.5%;
    background-color: #FDFDFD;
    transition: width 200ms;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.sidebar-icon {
    width: 0.75rem;
    width: 0.75rem;
    margin: 2.5%;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.sidebar-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    transition: all;
    font-size: 10px;
    text-align: left;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 1;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.sidebar-items .item {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.025);
}

.sidebar-items .itemSel {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    color: #B061D0;
    background-color: rgba(0, 0, 0, 0.025);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}

.sidebar-items .item:hover {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.025);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #5535B8;
}

.Sidebar.collapsed {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92.5%;
    width: 4rem;
    background-color: #FDFDFD;
    transition: width 200ms;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.Sidebar.collapsed .sidebar-text {
    display: none;
}

.Sidebar.collapsed .sidebar-icon {

    width: 0.75rem;
    width: 0.75rem;
    margin: 2.5%;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.CardWidth {
    width: auto;
    min-width: 60%;
    height: auto;
    max-height: 80%;
    background-color: #FFFFFF;
    padding: 2.5%;
    border-radius: 5px;
    scrollbar-width: none;
    overflow: auto;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #B061D0;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #F6F6F6;
  --neutral-color: #fff;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: #777;
    color: var(--text-color);
    background: #F6F6F6;
    background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
  background: #fff;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: #B061D0;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #fff;
  background: var(--neutral-color);
  border: 1px solid #eee;
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  transition: .25s ease-out;
  color: #B061D0;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #ccc;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 4rem;
  padding: 10px;
  border-right: 1px solid #eee;
  border-right: 1px solid var(--border-color);
  cursor: pointer;
  background: #fff;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  position: relative;
  height: 4rem;
  padding: 10px;
  cursor: pointer;
  background: #F6F6F6;
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #B061D0 0%,#B061D0 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  font-weight: 700;
}

.calendar .body .disabled {
  color: #ccc;
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #B061D0;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  position: relative;
  height: 4rem;
  padding: 10px;
  cursor: pointer;
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}
