.Sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
    height: 92.5%;
    background-color: #FDFDFD;
    transition: width 200ms;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.sidebar-icon {
    width: 0.75rem;
    width: 0.75rem;
    margin: 2.5%;
    cursor: pointer;
    user-select: none;
}

.sidebar-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    transition: all;
    font-size: 10px;
    text-align: left;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 1;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.sidebar-items .item {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.025);
}

.sidebar-items .itemSel {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    color: #B061D0;
    background-color: rgba(0, 0, 0, 0.025);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}

.sidebar-items .item:hover {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.025);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #5535B8;
}

.Sidebar.collapsed {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92.5%;
    width: 4rem;
    background-color: #FDFDFD;
    transition: width 200ms;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.Sidebar.collapsed .sidebar-text {
    display: none;
}

.Sidebar.collapsed .sidebar-icon {

    width: 0.75rem;
    width: 0.75rem;
    margin: 2.5%;
    cursor: pointer;
    user-select: none;
}